import { generateGUID } from "./store/CartSlice";
import masterCard from "../images/payments/MasterCard.jpg";
import amEx from "../images/payments/american-express.jpg";
import amazon from "../images/payments/amazon-pay.jpg";
import cash from "../images/payments/cash-app.jpg";
import visa from "../images/payments/visa.jpg";
import dinersClub from "../images/payments/diners-club.jpg";
import klarna from "../images/payments/klarna.jpg";
import imageOneOz from "../images/products/1oz-bottle.png";
import imageRusticPines from "../images/products/rustic-pines.jpg";

// Using require.context to import all images from the products directory
const importAll = (requireContext: __WebpackModuleApi.RequireContext) => requireContext.keys().map(requireContext);

const apts02Images = importAll(require.context('../images/products/apts02', false, /\.(png|jpe?g|svg)$/));
const apts03Images = importAll(require.context('../images/products/apts03', false, /\.(png|jpe?g|svg)$/));
const apht01Images = importAll(require.context('../images/products/apht01', false, /\.(png|jpe?g|svg)$/));



export const websiteURL: string = 'http://localhost:3000/';
export const serverURL: string = process.env.REACT_APP_SERVER_URL || 'http://localhost:4242/';
export const stripeSecret: string = process.env.REACT_APP_STRIPE_SECRET || 'pk_test_51PiPaeLQfgD2l9pcAPVOF8KLGvXFEJAeQ1wo9rGSLHlOeZxUkfbERPgIkaZSiAJBwjPG8WKh3p6ZG6zouUN6HFoD00rG9vZejM';
export const shippingId: string = process.env.REACT_APP_SHIPPING_ID || 'shr_1Q0lgfLQfgD2l9pcsgj0zUSO';
// export const discountId: string = 'none';
export const shippingRate: number = 0;

export interface Product {
    buyImage: string;
    category: string;
    description: string;
    guid: string;
    handcrafted: boolean;
    howTo: string;
    id: string;
    images: string[];
    ingredients: string[];
    keyIngredients: string[];
    productType: string;
    name: string;
    price: number;
    priceId: string;
    selectedSize: string;
    sizes: string[];
    sku: string;
    quantity: number;
    include: boolean;
}

export const defaultProduct: Product = {
    id: 'default',
    productType: 'default',
    name: 'default',
    price: 999.99,
    images: ['https://dummyimage.com/600x600/dee2e6/6c757d.jpg'],
    buyImage: 'https://dummyimage.com/600x600/dee2e6/6c757d.jpg',
    guid: generateGUID(),
    sku: 'default',
    priceId: 'default',
    quantity: 1,
    description: 'default',
    ingredients: ['default'],
    howTo: 'default',
    keyIngredients: ['default'],
    handcrafted: true,
    sizes: [],
    selectedSize: '',
    category: 'default',
    include: false
};

export const prod_QdLu2CBXXkO0LM: Product = {
    id: process.env.REACT_APP_PROD01_ID || 'prod_QdLu2CBXXkO0LM',
    productType: 'Beard Oil',
    name: 'Rustic Pines',
    price: 12.00,
    images: [imageOneOz],
    buyImage: imageRusticPines,
    guid: generateGUID(),
    sku: 'BO-SC-01',
    priceId: process.env.REACT_APP_PROD01_PRICE_ID || 'price_1Q8QesLQfgD2l9pcUvEtgmhb',
    quantity: 1,
    description: 'Rustic Pines isn\'t just about scent—it\'s about bringing the essence of nature into your daily grooming routine. Our all-natural formula nourishes and softens your beard, leaving it feeling smooth, healthy, and subtly fragranced. Perfect for the modern man who appreciates the rugged charm of the wilderness.',
    ingredients: ['Sunflower Seed Oil', 'Jojoba Oil', 'Grapeseed Oil', 'Pumpkin Seed Oil', 'Virginian Cedarwood Oil', 'Scotch Pine Oil', 'Natural Fragrance'],
    howTo: 'Dispense a few drops of beard oil into your palm and rub your hands together. Massage the oil into your beard and the skin beneath. Finally, groom and style your beard as desired.',
    keyIngredients: ['sunflower', 'pine', 'cedar'],
    handcrafted: true,
    sizes: [],
    selectedSize: 'Rustic Pines',
    category: 'grooming',
    include: true
};

export const prod_Qgsw9mnAPtbKEd: Product = {
    id: 'prod_Qgsw9mnAPtbKEd',
    productType: 'Beard Oil',
    name: 'Unscented',
    price: 11.00,
    images: [imageOneOz],
    buyImage: 'https://dummyimage.com/600x600/dee2e6/6c757d.jpg',
    guid: generateGUID(),
    sku: 'BO-NS-01',
    priceId: 'price_1Q8Qm4LQfgD2l9pcnHP3LRZj',
    quantity: 1,
    description: 'Our signature all-natural formula nourishes and softens your beard without any added fragrance, letting you enjoy the benefits of our expertly crafted blend without compromise. Perfect for the modern man who values simplicity and quality.',
    ingredients: ['Sunflower Seed Oil', 'Jojoba Oil', 'Grapeseed Oil', 'Pumpkin Seed Oil'],
    howTo: 'Dispense a few drops of beard oil into your palm and rub your hands together. Massage the oil into your beard and the skin beneath. Finally, groom and style your beard as desired.',
    keyIngredients: ['sunflower', 'jojoba', 'pumpkin'],
    handcrafted: true,
    sizes: [],
    selectedSize: 'Unscented',
    category: 'grooming',
    include: false,
}

export const prod_RGUP4bGSdhznLY: Product = {
    id: 'prod_RGUP4bGSdhznLY',
    productType: 'T-SHIRT',
    name: 'Black Mustache',
    price: 25.00,
    images: [apts02Images[0], apts02Images[1]],
    buyImage: apts02Images[0],
    guid: generateGUID(),
    sku: 'AP-TS-02',
    priceId: 'price_1QNzUiLQfgD2l9pcALIGa7mQ',
    quantity: 1,
    description: 'Show your support for Heartland Grooming Co. with this Mustache Logo Tee. Perfect for fans of the brand, this t-shirt offers a relaxed fit and a super soft feel, making it as comfortable as it is stylish. Whether you\'re running errands, hanging out, or representing Heartland Grooming Co. wherever you go, this tee is a great way to show you\'re part of the Heartland family.',
    ingredients: ['100% ring-spun cotton', 'Relaxed fit', 'Screen-printed front logo', 'Garment-dyed, pre-shrunk fabric', 'Screen-printed back logo', 'This product is made on demand'],
    howTo: '',
    keyIngredients: [],
    handcrafted: false,
    sizes: ['S', 'M', 'L', 'XL'],
    selectedSize: '',
    category: 'apparel',
    include: false,
}

export const prod_QqQjea7MxHsxqL: Product = {
    id: 'prod_QqQjea7MxHsxqL',
    productType: 'T-SHIRT',
    name: 'White Mustache',
    price: 25.00,
    images: [apts03Images[0], apts03Images[1]],
    buyImage: apts03Images[0],
    guid: generateGUID(),
    sku: 'AP-TS-03',
    priceId: 'price_1QNzVZLQfgD2l9pcjADlC1zK',
    quantity: 1,
    description: 'Show your support for Heartland Grooming Co. with this Mustache Logo Tee. Perfect for fans of the brand, this t-shirt offers a relaxed fit and a super soft feel, making it as comfortable as it is stylish. Whether you\'re running errands, hanging out, or representing Heartland Grooming Co. wherever you go, this tee is a great way to show you\'re part of the Heartland family.',
    ingredients: ['100% ring-spun cotton', 'Relaxed fit', 'Screen-printed front logo', 'Garment-dyed, pre-shrunk fabric', 'Screen-printed back logo', 'This product is made on demand'],
    howTo: '',
    keyIngredients: [],
    handcrafted: false,
    sizes: ['S', 'M', 'L', 'XL'],
    selectedSize: '',
    category: 'apparel',
    include: false,
}

export const prod_R0RovDTECVAjIQ: Product = {
    id: 'prod_R0RovDTECVAjIQ',
    productType: 'HAT',
    name: 'Leather Patch',
    price: 25.00,
    images: [apht01Images[0]],
    buyImage: apht01Images[0],
    guid: generateGUID(),
    sku: 'AP-HT-01',
    priceId: 'price_1Q8QubLQfgD2l9pcvQ8skOqN',
    quantity: 1,
    description: 'Top off your look with this Heartland Grooming Co. trucker hat, designed for both style and comfort. This hat features a low-profile, six-panel construction for a tailored fit, with soft trucker mesh in the back to keep you cool. The precurved bill and adjustable snapback ensure the perfect fit, while the rugged leather patch on the front adds a touch of refined elegance. Whether you\'re out working or just out and about, this hat blends casual style with quality craftsmanship, making it ideal for the modern man.',
    ingredients: ['Low-profile, six-panel design', '60% Cotton/40% Polyester front', '100% Polyester mesh back', 'Adjustable snapback closure', 'Genuine leather patch on the front', 'Precurved bill for a ready-to-wear fit'],
    howTo: '',
    keyIngredients: [],
    handcrafted: false,
    sizes: [],
    selectedSize: '',
    category: 'apparel',
    include: false,
}

// Store all products in an array
// , prod_QqQjea7MxHsxqL
export const productsList: Product[] = [prod_QdLu2CBXXkO0LM, prod_Qgsw9mnAPtbKEd, prod_R0RovDTECVAjIQ, prod_RGUP4bGSdhznLY, prod_QqQjea7MxHsxqL];

// Optionally, you can create a lookup function by ID
export const getProductById = (id: string): Product | undefined => {
    return productsList.find(product => product.id === id);
};

export const getProductBySku = (sku: string): Product | undefined => {
    return productsList.find(product => product.sku === sku);
};

export const paymentOptions = {
    visa: {
        alt: 'Visa',
        image: visa
    },
    mastercard: {
        alt: 'MasterCard',
        image: masterCard
    },
    amex: {
        alt: 'American Express',
        image: amEx
    },
    diners: {
        alt: 'Diners Club',
        image: dinersClub
    },
    amazon: {
        alt: 'Amazon Pay',
        image: amazon
    },
    cash: {
        alt: 'Cash App Pay',
        image: cash
    },
    klarna: {
        alt: 'Klarna Pay',
        image: klarna
    }
};
